import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { Card, Button, Tooltip, List, Avatar, Pagination, Cascader, Spin } from 'antd';
import { FundProjectionScreenOutlined, InsertRowAboveOutlined, RobotOutlined, ApiOutlined, HomeOutlined, BranchesOutlined, AlertOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../../tools/ToolRequest';

import SpinFull from '../../../components/SpinFull';

export default function StationDashboardOverviewEvent(props) {
  const {
    station,
  } = props;

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [eventRes, setEventRes] = useState({});
  const [tag, setTag] = useState([null]);
  const [paginationCurrent, setPaginationCurrent] = useState(1);

  useEffect(() => {
    loadEvent();
  }, [tag, paginationCurrent]);

  const loadEvent = async () => {
    setLoading(true);

    const eventRes = await ToolRequest.request('GET', '/v1/station-event', {
      stationId: station.id,
      from: (paginationCurrent - 1) * 10,
      count: 10,
      tag: tag[0] || undefined,
    });

    setEventRes(eventRes);
    setLoading(false);
    setLoaded(true);
  };

  const renderEventItem = (event) => {
    const descriptionValueMatched = event.description.match(/\{\d+\}/g);
    const descriptionValueMatcheds = [];

    let index = 0;
    for (let matched of descriptionValueMatched) {
      const foundIndex = event.description.indexOf(matched, index);
      descriptionValueMatcheds.push({
        matched,
        key: parseInt(matched.slice(1, -1)),
        foundIndex,
      });
      index = foundIndex + matched.length;
    }

    index = 0;
    const content = [];
    for (let i = 0; i < descriptionValueMatcheds.length; ++i) {
      const descriptionValueMatched = descriptionValueMatcheds[i];

      content.push(
        <span key={`content-${i}-1`}>
          {event.description.substr(index, descriptionValueMatched.foundIndex - index)}
        </span>
      );

      content.push(
        event.descriptionValues[descriptionValueMatched.key] === null ? '?' : (
          <b key={`content-${i}-2`}>
            {event.descriptionValues[descriptionValueMatched.key].value === null
              ? (event.descriptionValues[descriptionValueMatched.key].id ? event.descriptionValues[descriptionValueMatched.key].id.substr(0, 8) : '?')
              : event.descriptionValues[descriptionValueMatched.key].value}
          </b>
        )
      );

      index = descriptionValueMatched.foundIndex + descriptionValueMatched.matched.length;

      if (i === descriptionValueMatcheds.length - 1) {
        content.push(
          <span key={`content-${i}-3`}>
            {event.description.substr(index)}
          </span>
        );
      }
    }

    // icon
    let icon = null;
    switch (event.type) {
      case 'SYSTEM': {
        icon = <FundProjectionScreenOutlined />;
        break;
      }
      case 'ZONE': {
        icon = <InsertRowAboveOutlined />;
        break;
      }
      case 'AUTOMATION': {
        icon = <RobotOutlined />;
        break;
      }
      case 'DEVICE': {
        icon = <ApiOutlined />;
        break;
      }
      case 'STATION': {
        icon = <HomeOutlined />;
        break;
      }
      case 'IO': {
        icon = <BranchesOutlined />;
        break;
      }
      case 'MONITOR': {
        icon = <AlertOutlined />;
        break;
      }
    }

    return (
      <List.Item key={event.id}>
        <List.Item.Meta
          avatar={(
            <Avatar
              className={event.tags.indexOf('IMPORTANT') > -1 ? 'is-important' : ''}
              icon={icon}
            />
          )}
          title={content}
          description={(
            <Tooltip title={moment(event.createdAt).format('YYYY-MM-DD HH:mm:ss')}>
              {moment(event.createdAt).fromNow()}
            </Tooltip>
          )}
        />
      </List.Item>
    );
  };

  return (
    <Card
      id="eventContainer"
      title="Latest Events"
      className="card-container"
      style={{
        height: 274,
      }}
      actions={[
        loaded ? (
          <Pagination
            size="small"
            total={eventRes.totalCount}
            current={paginationCurrent}
            pageSize={10}
            simple={true}
            onChange={(page, pageSize) => setPaginationCurrent(page)}
          />
        ) : <span />,
        <Cascader
          value={tag}
          onChange={value => setTag(value)}
          allowClear={false}
          size="small"
          style={{
            width: 140,
          }}
          options={[
            {
              label: 'Settings',
              value: 'SETTING',
            },
            {
              label: 'Remote Action',
              value: 'MANUAL',
            },
            {
              label: 'On-site Action',
              value: 'STATION',
            },
            {
              label: 'Important',
              value: 'IMPORTANT',
            },
            {
              label: 'All',
              value: null,
            },
          ]}
        />
      ]}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <List
            size="small"
            dataSource={eventRes.data}
            renderItem={renderEventItem}
          />
        </Spin>
      ) : <SpinFull />}
    </Card>
  );
};
