import React, { useState, useEffect } from 'react';

import { Spin, Modal } from 'antd';

import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import { Point, Simplify } from 'curvereduce';

import * as ToolRequest from '../../../tools/ToolRequest';

export default function StationDashboardGraphInputChart(props) {
  const {
    zoneId,
    ioName,
    settings,
    beginAt,
    endAt,
    suffix,
    min,
    max,
    interval,
    formatter = v => v,
    labelFormatter = v => v,
    showMarker = true,
    color = '#2C5BFF',
  } = props;

  const [chartData, setChartData] = useState({
    prev: [],
    base: [],
    baseSimplified: [],
    next: [],
  });
  const [markAreaData, setMarkAreaData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modelOpened, setModelOpened] = useState(false);
  const [modelVisible, setModelVisible] = useState(false);

  const period = moment(endAt).diff(beginAt, 'day') + 1;

  useEffect(() => {
    (async () => {
      const sensorRecordsRes = await ToolRequest.request('GET', '/v1/station-sensor-record-quick', {
        ioName,
        zoneId,
        beginAt: beginAt.toISOString(),
        endAt: endAt.toISOString(),
      });

      const sensorRecordBoundaryRes = await ToolRequest.request('GET', '/v1/station-sensor-record-quick-boundary', {
        ioName,
        zoneId,
        beginAt: beginAt.toISOString(),
        endAt: endAt.toISOString(),
      });

      const rangeDiff = endAt.valueOf() - beginAt.valueOf() - 1;

      const chartDataNew = {
        prev: [],
        base: sensorRecordsRes.data,
        baseSimplified: [],
        next: [],
      };

      // prev & next
      if (sensorRecordsRes.data.length) {
        // prev
        if (sensorRecordBoundaryRes.prevValue !== null) {
          const prevTimeRange = beginAt.valueOf() - moment(sensorRecordBoundaryRes.prevCreatedAt).valueOf() + sensorRecordsRes.data[0].timeOffset;
          const valueProportional = (sensorRecordBoundaryRes.prevValue - sensorRecordsRes.data[0].value) * sensorRecordsRes.data[0].timeOffset / prevTimeRange + sensorRecordsRes.data[0].value;

          chartDataNew.prev.push({
            timeOffset: 0,
            value: valueProportional,
          });
          chartDataNew.prev.push({
            timeOffset: sensorRecordsRes.data[0].timeOffset,
            value: sensorRecordBoundaryRes.prevValue,
          });
        } else {
          chartDataNew.prev.push({
            timeOffset: 0,
            value: 0,
          });
          chartDataNew.prev.push({
            timeOffset: sensorRecordsRes.data[0].timeOffset,
            value: 0,
          });
        }

        // next
        if (sensorRecordBoundaryRes.nextValue !== null) {
          const nextTimeRange = moment(sensorRecordBoundaryRes.nextCreatedAt).valueOf() - (beginAt.valueOf() + sensorRecordsRes.data[sensorRecordsRes.data.length - 1].timeOffset);
          const valueProportional = (sensorRecordBoundaryRes.nextValue - sensorRecordsRes.data[sensorRecordsRes.data.length - 1].value) * (rangeDiff - sensorRecordsRes.data[sensorRecordsRes.data.length - 1].timeOffset) / nextTimeRange + sensorRecordsRes.data[sensorRecordsRes.data.length - 1].value;

          chartDataNew.next.push({
            timeOffset: sensorRecordsRes.data[sensorRecordsRes.data.length - 1].timeOffset,
            value: valueProportional,
          });
          chartDataNew.next.push({
            timeOffset: rangeDiff,
            value: sensorRecordBoundaryRes.nextValue,
          });
        } else {
          chartDataNew.next.push({
            timeOffset: sensorRecordsRes.data[sensorRecordsRes.data.length - 1].timeOffset,
            value: sensorRecordsRes.data[sensorRecordsRes.data.length - 1].value,
          });
          chartDataNew.next.push({
            timeOffset: rangeDiff,
            value: sensorRecordsRes.data[sensorRecordsRes.data.length - 1].value,
          });
        }
      } else {
        // only prev
        if (sensorRecordBoundaryRes.prevValue !== null) {
          chartDataNew.prev.push({
            timeOffset: 0,
            value: sensorRecordBoundaryRes.prevValue,
          });
          chartDataNew.prev.push({
            timeOffset: rangeDiff,
            value: sensorRecordBoundaryRes.prevValue,
          });
        }
      }

      if (sensorRecordsRes.data.length) {
        // simplify graph
        const points = chartDataNew.base.map(record => ({
          x: record.timeOffset,
          y: record.value,
        }));
        const simplified = Simplify(points, 0.4);
        chartDataNew.baseSimplified = simplified.map(point => {
          return {
            timeOffset: point.x,
            value: point.y,
          };
        });

        // calculate markArea
        const markAreaData = [];

        let markAreaActive = false;
        let markAreaBeginAt = null;

        const targetValueRange = (max - min) * settings.targetValueSafePercent / 2 * 1.5;
        const targetValueLower = settings.targetValue - targetValueRange;
        const targetValueUpper = settings.targetValue + targetValueRange;

        for (let i = 0; i < chartData.length; ++i) {
          if (markAreaActive) {
            if (chartData[i][1] >= targetValueLower && chartData[i][1] <= targetValueUpper) {
              markAreaData.push([
                {
                  xAxis: markAreaBeginAt,
                },
                {
                  xAxis: chartData[i][0],
                },
              ]);
              markAreaActive = false;
            }
          } else {
             if (chartData[i][1] < targetValueLower || chartData[i][1] > targetValueUpper) {
               markAreaBeginAt = chartData[i][0];
               markAreaActive = true;
             }
          }
        }

        if (markAreaActive) {
          markAreaData.push([
            {
              xAxis: markAreaBeginAt,
            },
            {
              xAxis: endAt.format('YYYY-MM-DD HH:mm:ss'),
            },
          ]);
        }
      }

      const convertSeries = (series) => {
        return series.map(record => {
          return [
            moment(beginAt).add(record.timeOffset).format('YYYY-MM-DD HH:mm:ss'),
            record.value,
          ];
        })
      };

      setChartData({
        prev: convertSeries(chartDataNew.prev),
        base: convertSeries(chartDataNew.base),
        baseSimplified: convertSeries(chartDataNew.baseSimplified),
        next: convertSeries(chartDataNew.next),
      });

      setMarkAreaData(markAreaData);

      setLoading(false);
    })();
  }, []);

  const option = {
    title: {
      text: ioName,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'time',
      boundaryGap: false,
    },
    yAxis: {
      type: 'value',
      interval,
      min,
      max,
      axisLabel: {
        formatter: labelFormatter,
      },
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none'
        },
      }
    },
    dataZoom: [{
      type: 'inside',
    }],
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        let dataArrs = [];
        if (params.some(param => param.seriesIndex === 1)) {
          dataArrs.push(`<div>${params[0].marker}<b>${formatter(params[0].value[1])}${suffix || ''}</b></div>`);
        }
        return `${moment(params[0].value[0]).format('YYYY-MM-DD HH:mm:ss')}` + dataArrs.join('');
      },
      axisPointer: {
        animation: false
      },
    },
    series: [
      {
        type: 'line',
        symbolSize: 0,
        data: [],   // override below
        name: ioName,
        lineStyle: {
          color,
          type: 'solid',
          opacity: .4,
        },
        itemStyle: {
          color,
        },
      },
      {
        type: 'line',
        symbolSize: 0,
        data: [],   // override below
        lineStyle: {
          color,
          type: 'solid',
        },
        itemStyle: {
          color,
        },
        ...(markAreaData.length ? {
          markArea: {
            itemStyle: {
              color: 'rgba(255, 173, 177, 0.4)',
            },
            data: markAreaData,
          },
        } : {}),
        ...(showMarker ? {
          markPoint: {
            data: [
              { type: 'max', name: 'Highest' },
              { type: 'min', name: 'Lowest' },
            ],
            label: {
              formatter: (param) => {
                return formatter(param.data.value);
              },
            },
          },
          markLine: {
            data: [
              { type: 'average', name: 'Average' },
            ],
            label: {
              position: 'insideEndTop',
              formatter: (param) => {
                return formatter(param.data.value);
              },
            },
          },
        } : {}),
      },
      {
        type: 'line',
        symbolSize: 0,
        data: [],   // override below
        name: ioName,
        lineStyle: {
          color,
          type: 'solid',
          opacity: .4,
        },
        itemStyle: {
          color,
        },
      },
    ],
  };

  return (
    <>
      <Spin spinning={loading}>
        <div style={{ display: 'none' }}>{chartData.length} -> {chartData.baseSimplified.length}</div>
        <div
          onClick={() => {
            setModelOpened(true);
            setModelVisible(true);
          }}
        >
          <ReactECharts
            {...props}
            opts={{
              useDirtyRect: true,
            }}
            option={{
              ...option,
              toolbox: null,
              dataZoom: null,
              tooltip: null,
              series: [
                {
                  ...option.series[0],
                  data: chartData.prev,
                  silent: true,
                },
                {
                  ...option.series[1],
                  data: chartData.baseSimplified,
                  silent: true,
                },
                {
                  ...option.series[2],
                  data: chartData.next,
                  silent: true,
                },
              ],
              animation: false,
            }}
          />
        </div>

        {modelOpened && (
          <Modal
            title={ioName}
            visible={modelVisible}
            onCancel={() => setModelVisible(false)}
            width="80%"
            footer={null}
          >
            <ReactECharts
              {...props}
              opts={{
                useDirtyRect: true,
              }}
              style={{height: 400}}
              option={{
                ...option,
                series: [
                  {
                    ...option.series[0],
                    data: chartData.prev,
                  },
                  {
                    ...option.series[1],
                    data: chartData.base,
                  },
                  {
                    ...option.series[2],
                    data: chartData.next,
                  },
                ],
              }}
            />
          </Modal>
        )}
      </Spin>
    </>
  );
};
