import React, { useState, useEffect } from 'react';

import { Spin, Modal } from 'antd';

import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import { Point, Simplify } from 'curvereduce';

import * as ToolRequest from '../../../tools/ToolRequest';

export default function StationDashboardGraphOutputChart(props) {
  const {
    zoneId,
    ioName,
    beginAt,
    endAt,
    color = '#2C5BFF',
  } = props;

  const [chartData, setChartData] = useState({
    prev: [],
    base: [],
    baseSimplified: [],
    next: [],
  });
  const [loading, setLoading] = useState(true);
  const [modelOpened, setModelOpened] = useState(false);
  const [modelVisible, setModelVisible] = useState(false);

  const period = moment(endAt).diff(beginAt, 'day') + 1;

  useEffect(() => {
    (async () => {
      const relayRecordsRes = await ToolRequest.request('GET', '/v1/station-control-record-quick', {
        ioName,
        zoneId,
        beginAt: beginAt.toISOString(),
        endAt: endAt.toISOString(),
      });

      const relayRecordBoundaryRes = await ToolRequest.request('GET', '/v1/station-control-record-quick-boundary', {
        ioName,
        zoneId,
        beginAt: beginAt.toISOString(),
        endAt: endAt.toISOString(),
      });

      const rangeDiff = endAt.valueOf() - beginAt.valueOf() - 1;

      const chartDataNew = {
        prev: [
          ...(relayRecordBoundaryRes.prevValue !== null ? [
            {
              timeOffset: 0,
              value: relayRecordBoundaryRes.prevValue,
            },
          ] : [
            {
              timeOffset: 0,
              value: false,
            },
          ]),
        ],
        base: [],
        baseSimplified: [],
        next: [
          ...(relayRecordsRes.data.length ? [
            {
              timeOffset: rangeDiff,
              value: relayRecordsRes.data[relayRecordsRes.data.length - 1].value,
            },
          ] : []),
        ],
      };

      if (relayRecordsRes.data.length) {
        for (let i = 0; i < relayRecordsRes.data.length; ++i) {
          if (i > 0) {
            if (relayRecordsRes.data[i - 1].value !== relayRecordsRes.data[i].value) {
              chartDataNew.base.push({
                timeOffset: relayRecordsRes.data[i].timeOffset,
                value: relayRecordsRes.data[i - 1].value,
              });
            }
          }

          chartDataNew.base.push(relayRecordsRes.data[i]);
        }

        if (relayRecordsRes.data[0]) {
          chartDataNew.prev.push({
            timeOffset: relayRecordsRes.data[0].timeOffset,
            value: chartDataNew.prev[0].value,
          });

          if (relayRecordsRes.data[0].value !== chartDataNew.prev[1].value) {
            chartDataNew.base.unshift({
              timeOffset: relayRecordsRes.data[0].timeOffset,
              value: chartDataNew.prev[1].value,
            });
          }
        }

        if (relayRecordsRes.data.length) {
          chartDataNew.next.unshift(relayRecordsRes.data[relayRecordsRes.data.length - 1]);
        }

        // simplify graph
        const points = chartDataNew.base.map(record => ({
          x: record.timeOffset,
          y: record.value,
        }));
        const simplified = Simplify(points, 0.02);
        chartDataNew.baseSimplified = simplified.map(point => {
          return {
            timeOffset: point.x,
            value: point.y,
          };
        });
      } else {
        if (chartDataNew.prev[0]) {
          chartDataNew.prev.push({
            timeOffset: rangeDiff,
            value: chartDataNew.prev[0].value,
          });
        }
      }

      const convertSeries = (series) => {
        return series.map(record => {
          return [
            moment(beginAt).add(record.timeOffset).format('YYYY-MM-DD HH:mm:ss'),
            (record.value ? 1 : 0),
          ];
        })
      };

      setChartData({
        prev: convertSeries(chartDataNew.prev),
        base: convertSeries(chartDataNew.base),
        baseSimplified: convertSeries(chartDataNew.baseSimplified),
        next: convertSeries(chartDataNew.next),
      });

      setLoading(false);
    })();
  }, []);

  const option = {
    title: {
      text: ioName,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'time',
      boundaryGap: false,
    },
    yAxis: {
      type: 'value',
      max: 1,
      min: 0,
      interval: 1,
      axisLabel: {
        formatter: (value) => {
          return ['OFF', 'ON'][value];
        },
      },

    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none'
        },
      }
    },
    dataZoom: [{
      type: 'inside',
    }],
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const data = {};

        for (let param of params) {
          if (param.seriesIndex === 1) {
            if (!data[param.seriesName]) {
              data[param.seriesName] = {
                marker: param.marker,
                value: ['OFF', 'ON'][param.value[1]],
              };
            } else {
              if (data[param.seriesName].value === 'ON' && !param.value[1]) {
                data[param.seriesName].value = 'Switching OFF';
              } else if (data[param.seriesName].value === 'OFF' && param.value[1]) {
                data[param.seriesName].value = 'Switching ON';
              }
            }
          }
        }

        let dataArrs = [];
        for (let seriesName in data) {
          dataArrs.push(`<div>${data[seriesName].marker}${seriesName}:<b style="margin-left:4px">${data[seriesName].value}</b></div>`);
        }

        return `${moment(params[0].value[0]).format('YYYY-MM-DD HH:mm:ss')}<br>` + dataArrs.join('');
      },
      axisPointer: {
        animation: false
      },
    },
    series: [
      {
        type: 'line',
        symbolSize: 0,
        data: [],   // override below
        name: ioName,
        lineStyle: {
          color,
          type: 'solid',
          opacity: .4,
        },
        itemStyle: {
          color,
        },
        areaStyle: {},
      },
      {
        type: 'line',
        symbolSize: 0,
        data: [],   // override below
        name: ioName,
        lineStyle: {
          color,
          type: 'solid',
        },
        itemStyle: {
          color,
        },
        areaStyle: {},
      },
      {
        type: 'line',
        symbolSize: 0,
        data: [],   // override below
        name: ioName,
        lineStyle: {
          color,
          type: 'solid',
          opacity: .4,
        },
        itemStyle: {
          color,
        },
        areaStyle: {},
      },
    ],
  };

  return (
    <>
      <Spin spinning={loading}>
        <div style={{ display: 'none' }}>{chartData.base.length} -> {chartData.baseSimplified.length}</div>
        <div
          onClick={() => {
            setModelOpened(true);
            setModelVisible(true);
          }}
        >
          <ReactECharts
            {...props}
            opts={{
              useDirtyRect: true,
            }}
            option={{
              ...option,
              toolbox: null,
              dataZoom: null,
              tooltip: null,
              series: [
                {
                  ...option.series[0],
                  data: chartData.prev,
                  silent: true,
                },
                {
                  ...option.series[1],
                  data: chartData.baseSimplified,
                  silent: true,
                },
                {
                  ...option.series[2],
                  data: chartData.next,
                  silent: true,
                },
              ],
              animation: false,
            }}
          />
        </div>

        {modelOpened && (
          <Modal
            title={ioName}
            visible={modelVisible}
            onCancel={() => setModelVisible(false)}
            width="80%"
            footer={null}
          >
            <ReactECharts
              {...props}
              opts={{
                useDirtyRect: true,
              }}
              style={{height: 400}}
              option={{
                ...option,
                series: [
                  {
                    ...option.series[0],
                    data: chartData.prev,
                  },
                  {
                    ...option.series[1],
                    data: chartData.base,
                  },
                  {
                    ...option.series[2],
                    data: chartData.next,
                  },
                ],
              }}
            />
          </Modal>
        )}
      </Spin>
    </>
  );
};
